import axios from "axios";
import {mainStorage} from "@/composables/tools/main-storage.tools";
import {exit} from "@/composables/functionExit";

let interval = null;

export async function LogIn(data){
    try{
        const result = await axios.post("/globus/api/Auth/login", data);

        if (result.status === 200){
            await setRefresh(result.data);
            await setAccess(result.data);
            return {status: true, code: 200};
        } else {
            return {status: false, code: result.response.status};
        }

    } catch(e){
        console.log(e);
        return {status: false, code: e};
    }
}
async function setRefresh(data){
    mainStorage.setItem("refresh_token", data.refresh_token);
    mainStorage.setItem("refresh_time_end", getTimeEnd(data.refresh_expires_in));

    checkEndRefreshToken();
}


async function setAccess(data){
    mainStorage.setItem("access_token", data.access_token);
    mainStorage.setItem("access_time_end", getTimeEnd(data.expires_in));
}

export async function getToken(){
    try {
        return await getRefresh();
    } catch (e){
        console.log(e);
        return false;
    }
}
export async function getRefresh(){
    try {
        if (mainStorage.getItem("refresh_token")) {
            let result = await axios.post("/globus/api/Auth/refresh",
                {refresh_token: mainStorage.getItem("refresh_token")});
            await setRefresh(result.data);
            await setAccess(result.data);
            return result.data.access_token;
        } else return false;
    } catch(err) {
        exit();
        console.log(err);
        return false;
    }
}

function checkEndRefreshToken() {
    if(interval) {
        clearTimeout(interval);
    }

    const refreshTimeEnd = mainStorage.getItem("refresh_time_end");

    interval = setInterval(() => {
        if(Date.now() > refreshTimeEnd) {
            clearInterval(interval); 
            exit();
        }
    }, 10000);
}

function getTimeEnd(sec){
    const currentTime = Date.now();

    return currentTime + (sec * 1000);
}
