<template>
  <div class="panel-container">
    <div
      v-for="(button, index) in buttons"
      :key="index"
      class="panel-item"
      :class="{'panel-item_active': store.showMode === button.name}"
      @click="changeNav(button.name)"
    >
      <SvgButton
        height="24px"
        width="24px"
        class="icon"
        :name="button.name"
      />
      <p class="button-text">
        {{ t('lk_navigation_panel.' + button.name) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import SvgButton from "@/components/common/svgButton.vue";
import {buttons} from "@/composables/constans/buttonTypes"; 
import {useStore} from "@/store";
import { t } from "@/controllerLK/GlobusLocalization";

const store = useStore();

const changeNav = (type) => {
  if (type === "exit") {
    store.isShowLogoutDialog = true;
    return;
  }
  store.showMode = type;
};
</script>

<style
    lang="scss"
    scoped
>

@import '@/assets/styles/vars.scss';

.icon {
  color: #A7A7A7;
}

.panel-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 2px 4px 20px 0 #0020390F;
  width: 335px;
  padding: 20px;
  box-sizing: border-box;
  min-height: 700px;
}

.panel-item {
  display: flex;
  gap: 4px;
  padding: 6px 14px 6px 14px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray;
  }
}

.panel-item_active {
  background-color: $light-gray;
}
</style>
