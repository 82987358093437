<template>
  <div
    v-if="!item.disabled"
    class="routing__item"
    :class="{'routing__item_active': activeRoute === item.route}"
  >
    <SvgIcon
      :name="item.icon"
      @click="changeRoute(item.route)"
      @mouseenter="isShowTooltip = true"
      @mouseleave="isShowTooltip = false"
    />

    <div
      v-if="isShowTooltip"
      class="router-tooltip"
    >
      {{ t('router_item.' + item.id) }}
    </div>
  </div>
</template>

<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import { t } from "@/controllerLK/GlobusLocalization";
import {useProjects} from "@/store/projects";
import {useStore} from "@/store";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  }
});

const router = useRouter();
const projectStore = useProjects();
const store = useStore();
const activeRoute = computed(() => router.currentRoute.value.fullPath);
const isShowTooltip = ref(false);

const changeRoute = (route) => {
  if (props.item.id === "map" && projectStore.activeProject.mapFiles.length === 0) {
    store.createNotify(t("router_item.error.no_map"), "error");
    return false; 
  }

  router.push(route);

};
</script>

<style lang="scss" scoped>

.router-tooltip {
  position: absolute;
  top: 50px;
  left: 0;
  padding: 5px 10px;
  color: black;
  border-radius: 10px;
  background: white;
  z-index: 2000;
}

.routing__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #7B87A5;
  border-radius: 50%;
  transition: all .3s ease-in-out;

  &_active {
    color: #1F9AFF;
    background: #F4F7FE;
  }

  &:hover {
    background: #F4F7FE;
  }

  svg {
    width: 21px;
    height: 20px;
  }
}
</style>