<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {ref} from "vue";
import AccessListModal
  from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalControl/AccessListModal.vue";
import { t } from "@/controllerLK/GlobusLocalization";

const props = defineProps({
  isEdit: {
    type: Boolean,
    required: true,
    default: false
  },
  isFormSubmitted: {
    type: Boolean,
    required: true,
  },
  project: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(["closeModal", "updateProjectList", "createProject", "addUserList"]);

const showUserList = ref(false);
</script>

<template>
  <div class="project-control">
    <div
      class="project-control__add-member"
      @click="showUserList = true"
    >
      <SvgIcon
        height="15px"
        name="access-control-blue"
        width="15px"
      />

      <span>{{ t('project_modal_control.title') }}</span>

      <SvgIcon
        height="15px"
        name="lock-blue"
        width="15px"
      />
    </div>
    <div
      class="project-control__create"
      @click="!props.isFormSubmitted ? emit('create-project') : false"
    >
      <span v-if="!isEdit">{{ t('project_modal_control.button.create') }}</span>
      <span v-else>{{ t('project_modal_control.button.save') }}</span>
    </div>
  </div>

  <AccessListModal
    :is-edit="props.isEdit"
    :user-list="props.project.users"
    :show-user-list="showUserList"
    @add-user-list="val => emit('addUserList', val)"
    @close-modal="showUserList = false"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.project-control {
  display: flex;
  justify-content: space-between;
  user-select: none;

  &__add-member {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 220px;
    height: 40px;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    color: $globe-active-color;
    border-radius: 8px;
    background: $background-color;
    gap: 10px;
  }

  &__add-member:hover {
    background: $hover-active-color;
  }

  &__create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 40px;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    color: #FFFFFF;
    border-radius: 8px;
    background: $blue-dark;
  }
}
</style>