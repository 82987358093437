<script setup>

import {useStore} from "@/store";
import CrossIcon from "@/assets/icons/CrossIcon.vue";
import {useProjects} from "@/store/projects";
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import {computed} from "vue";
import {modules} from "@/composables/constans/modules";
import SvgIcon from "@/components/common/svgButton.vue";
import {t} from "@/controllerLK/GlobusLocalization";

const store = useStore();
const projectStore = useProjects();

const MoveToProject = () => {
  store.isShowProjectNoModuleDialog = false;
  store.showMode = "projects";
};


const projectModuleList = computed(() => {
  return modules.filter((module) => {
    return projectStore.activeProject?.modules?.includes(module.dataName) && module.dataName !== "module_main";
  });
});

const activeProject = computed(() => projectStore.activeProject?.name);

</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog">
      <div class="dialog-header">
        <div class="dialog-header__title">
          {{ t('project_hasnt_module_dialog.title.one') }} "{{ activeProject }}"
          {{ t('project_hasnt_module_dialog.title.two') }}
          "{{ t('project_hasnt_module_dialog.modules.' + store.activeModule.dataName) }}"
        </div>
        <div class="dialog-header-button">
          <CrossIcon
            class="dialog-header-button__close"
            @click="store.isShowProjectNoModuleDialog = false;"
          />
        </div>
      </div>
      <div class="dialog-body">
        <div class="dialog-body__title">
          {{ t('project_hasnt_module_dialog.available_services') }}:
        </div>
        <div class="dialog-body__list">
          <div class="service-list">
            <div
              v-for="module in projectModuleList"
              :key="module"
              class="service-list__item"
            >
              <SvgIcon
                style="width: 30px; height: 30px;"
                :name="module.dataName"
              />
              <span>
                {{ t('project_hasnt_module_dialog.modules.' + module.dataName) }}
              </span>
            </div>
          </div>
        </div>
        <div class="dialog-body__message">
          {{ t('project_hasnt_module_dialog.message.one') }}
          "{{ t('project_hasnt_module_dialog.modules.' + store.activeModule.dataName) }}"
          {{ t('project_hasnt_module_dialog.message.two') }}.
        </div>
      </div>
      <div class="dialog-footer">
        <GlobusButtonPrimary
          class="dialog-footer__button"
          @click="MoveToProject"
        >
          {{ t('project_hasnt_module_dialog.button.to_project') }}
        </GlobusButtonPrimary>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.dialog {
  display: flex;
  width: 300px;
  padding: 14px;
  flex-direction: column;
  border-radius: 8px;
  background: #FFF;
  gap: 14px;
  box-shadow: 1px 2px 10px 0 #131E2726;

  &-footer {
    &__button {
      width: 100%;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #F4FAFF;
    border-radius: 10px;
    padding: 20px;

    &__list {
      .service-list {
        display: flex;
        flex-direction: column;
        gap: 5px;


        &__item {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.1em;
          }
        }
      }
    }

    &__title {
      word-break: break-all;
      color: #373D3F;
      font-size: 14px;
      font-weight: 400;
    }

    &__message {
      color: #373D3F;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;

    &-button {
      cursor: pointer;
      user-select: none;
      &__close {
        color: #A9A9A9;
        width: 18px;
        height: 18px;
      }
    }

    &__title {
      word-break: break-all;
      width: 80%;
      color: #000000D9;
      font-weight: 500;
    }
  }

}

.dialog-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

</style>