<script setup>
import {t} from "@/controllerLK/GlobusLocalization";
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import {onMounted, ref} from "vue";

onMounted(() => {
  console.log(projectMenuRef.value.style);
  console.log(props.coords);
  projectMenuRef.value.style.left = `${props.coords.x - 220}px`;
  projectMenuRef.value.style.top = `${props.coords.y - 10}px`;
});

const projectStore = useProjects();

const props = defineProps({
  isArchive: {
    type: Boolean,
    required: true,
  },
  project: {
    type: Object,
    required: true
  },
  coords: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(["editProject", "close-project-menu"]);

/* Перенос проекта в архив **/
const changeProjectArchive = async (status) => {
  let project = JSON.parse(JSON.stringify(props.project));

  project.isArchive = status;

  await projectStore.changeProjectArchive(project);
};

const projectMenuRef = ref("");

</script>

<template>
  <div
    ref="projectMenuRef"
    class="project-menu"
  >
    <div
      class="project-menu__item"
      @click="emit('editProject'); emit('close-project-menu')"
    >
      <SvgIcon
        width="14px"
        height="14px"
        :name="`edit`"
      />
      {{ t('lk_project_card.options.edit') }}
    </div>

    <div
      v-if="isArchive === false && project.id !== projectStore.activeProject.id"
      class="project-menu__item"
      @click="changeProjectArchive(true); emit('close-project-menu')"
    >
      <SvgIcon
        width="14px"
        height="14px"
        :name="`archive`"
      />
      {{ t('lk_project_card.options.to_archive') }}
    </div>

    <div
      v-if="isArchive === true"
      class="project-menu__item"
      @click="changeProjectArchive(false); emit('close-project-menu')"
    >
      <SvgIcon
        width="14px"
        height="14px"
        :name="`archive`"
      />
      {{ t('lk_project_card.options.from_archive') }}
    </div>

    <div
      v-if="project.id !== projectStore.activeProject.id"
      class="project-menu__item"
      @click="emit('deleteProject', project); emit('close-project-menu')"
    >
      <SvgIcon
        width="14px"
        height="14px"
        :name="`delete`"
      />
      {{ t('lk_project_card.options.delete') }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';

.project-menu {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 210px;
  padding: 6px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 1px 2px 10px 0 #131E2726;

  &__item {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding-left: 8px;
    border-radius: 8px;
    gap: 4px;

    &:hover {
      background: $hover-active-color;
    }
  }
}

</style>