<script setup>
import {defineProps, ref, defineEmits, watch} from "vue";
import { t } from "@/controllerLK/GlobusLocalization";
import {useProjects} from "@/store/projects";
import {createProjectForm} from "@/composables/constans/formData";
import {useStore} from "@/store";
import ProjectModalArchive from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalArchive.vue";
import ProjectModalHeader from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalHeader.vue";
import ProjectModalName from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalName.vue";
import ProjectModalModules from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalModules.vue";
import ProjectModalCountry from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry.vue";
import ProjectModalControl from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalControl.vue";
import BlockView from "@/components/common/BlockView.vue";
import GlobusDialog from "@/components/common/GlobusDialog.vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isArchive: {
    type: Boolean,
    default: false
  },
  project: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(["closeModal", "updateProjectList", "showDeleteProjectModal"]);

const projectStore = useProjects();
const mainStore = useStore();

const errorList = ref([]);

const createProjectFormData = ref(JSON.parse(JSON.stringify(createProjectForm)));

watch(() => props.show, async (status) => {
  if (status === true) {
    mapDeletedRegionList.value = [];
    mapNewRegionList.value = [];
    errorList.value = [];

    if (props.isEdit) {
      createProjectFormData.value = JSON.parse(JSON.stringify(props.project));
    } else {
      createProjectFormData.value = JSON.parse(JSON.stringify(createProjectForm));
    }

    newUserList.value = JSON.parse(JSON.stringify(createProjectFormData.value.users));
  }
});

const isShowUnsavedChangesDialog = ref(false);

const saveUnsavedChanges = () => {
  createProject();
  isShowUnsavedChangesDialog.value = false;
};

// Работа с картами.

const mapDeletedRegionList = ref([]);
const mapNewRegionList = ref([]);

const addRegionToList = (region) => {
  createProjectFormData.value.mapFiles.push(region);
  mapNewRegionList.value.push(region);
};

const removeRegionFromList = (region) => {
  // Если карта уже была в проекте ранее
  if (region.mapFilePath) {
    mapDeletedRegionList.value.push(region);

    const mapIndex = createProjectFormData.value.mapFiles
        .findIndex(mapFile => mapFile.mapFilePath === region.mapFilePath);

    createProjectFormData.value.mapFiles.splice(mapIndex, 1);
  } else {
    const mapIndex = createProjectFormData.value.mapFiles
        .findIndex(mapFile => mapFile.name === region.name);

    createProjectFormData.value.mapFiles.splice(mapIndex, 1);

    const mapNewIndex = createProjectFormData.value.mapFiles
        .findIndex(mapFile => mapFile.name === region.name);
    mapNewRegionList.value.splice(mapNewIndex, 1);
  }
};

// Создание проекта

const isFormSubmitted = ref(false);

const createProject = async () => {
  isFormSubmitted.value = true;
  errorList.value = [];
  createProjectFormData.value.name = createProjectFormData.value.name.trim();
  if (createProjectFormData.value.name.length === 0) {
    errorList.value.push("noName");
  }

  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)

  if (createProjectFormData.value.name.search(emojiRegEx) !== -1) {
    errorList.value.push("noEmoji");
  }

  const moduleList = createProjectFormData.value.modules;
  if (moduleList.length === 0) {
    errorList.value.push("noModule");
  }

  if (moduleList.length === 0) {
    errorList.value.push("noModule");
  }

  if (!errorList.value.length > 0) {
    mainStore.loader = true;
    // TODO: На беке на круд проекта должны убрать параметр mapFiles, после этого удалить строку ниже 24.12.24
    createProjectFormData.value.mapFiles = [];
    if (!props.isEdit) {
      const newProject = await projectStore.createProject(createProjectFormData.value);
      createProjectFormData.value.id = newProject.id;
    } else {
      await projectStore.updateProject(createProjectFormData.value);
    }
    //// Пользователи проекта

    const usersForDelete = createProjectFormData.value.users.filter(user => !newUserList.value.includes(user));
    const usersForAdd = newUserList.value.filter(user => !createProjectFormData.value.users.includes(user));

    if (usersForAdd.length > 0) {
      await projectStore.grantAccessToProject(createProjectFormData.value.id, usersForAdd);
    }

    if (usersForDelete.length > 0) {
      await projectStore.removeAccessFromProject(createProjectFormData.value.id, usersForDelete);
    }

    //// Карты
    // Удаление карт
    for (let region of mapDeletedRegionList.value) {
      await projectStore.deleteProjectMap(createProjectFormData.value.id, region);
    }
    // Создание карт
    for (let region of mapNewRegionList.value) {
      if (!region.file) {
        await projectStore.createMap(createProjectFormData.value.id, region.id, region.name);
      } else {
        await projectStore.uploadMap(createProjectFormData.value.id, region.file);
      }
    }
    await projectStore.getProjects();
    emit("closeModal");
  }
  mainStore.loader = false;
  isFormSubmitted.value = false;
};

//
const addUserList = (userList) => {
  console.log(userList);
  newUserList.value = userList.map((user) => user.userId);
};

const newUserList = ref([]);

</script>

<template>
  <BlockView
    v-if="props.show"
    @click="isEdit ? isShowUnsavedChangesDialog = true : emit('close-modal')"
  />

  <div
    v-if="props.show"
    class="modal-create-project"
  >
    <ProjectModalHeader
      :is-edit="props.isEdit"
      @close-modal="emit('closeModal')"
    />

    <ProjectModalName
      :error-list="errorList"
      :project-name="createProjectFormData.name"
      :project-description="createProjectFormData.description"
      @update:project-name="val => createProjectFormData.name = val"
      @update:project-description="val => createProjectFormData.description = val"
    />

    <ProjectModalArchive
      v-if="props.isEdit"
      :is-archive-page="props.isArchive"
      :is-archive="createProjectFormData.isArchive"
      @update:is-archive="val => createProjectFormData.isArchive = val"
      @show-delete-project-modal="emit('showDeleteProjectModal')"
      @close-project-modal="emit('closeModal')"
    />

    <ProjectModalModules
      :error-list="errorList"
      :active-module-list="createProjectFormData.modules"
      @add-module="val => createProjectFormData.modules.push(val)"
      @delete-module="val => createProjectFormData.modules.splice(val, 1)"
    />

    <ProjectModalCountry
      :map-new-region-list="mapNewRegionList"
      :map-deleted-region-list="mapDeletedRegionList"
      :map-files="createProjectFormData.mapFiles"
      :is-edit="props.isEdit"
      @add-region-to-list="val => addRegionToList(val)"
      @remove-region-from-list="val => removeRegionFromList(val)"
    />

    <ProjectModalControl
      ref="projectModalControlRef"
      :is-form-submitted="isFormSubmitted"
      :is-edit="props.isEdit"
      :project="createProjectFormData"
      @create-project="createProject"
      @update-project-list="emit('updateProjectList')"
      @add-user-list="val => addUserList(val)"
      @close-modal="emit('closeModal')"
    />
  </div>

  <GlobusDialog
    v-if="isShowUnsavedChangesDialog"
    :cancel-button-text="t('lk_project_modal.unsaved_changes.button.cancel')"
    :confirm-button-text="t('lk_project_modal.unsaved_changes.button.save')"
    class="unsaved-changed-dialog"
    :title="t('lk_project_modal.unsaved_changes.title')"
    @confirm="saveUnsavedChanges"
    @cancel="isShowUnsavedChangesDialog = false; emit('closeModal')"
  >
    <div class="unsaved-changed-dialog__text">
      {{ t('lk_project_modal.unsaved_changes.message') }}
    </div>
  </GlobusDialog>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.unsaved-changed-dialog {
  z-index: 2001;

  &__text {
    color: #373D3F;
  }
}

.modal-create-project {
  position: fixed;
  z-index: 2001;
  top: 0;
  right: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  width: 555px;
  height: 100%;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px 0 #00000024;
  gap: 30px;
}

</style>