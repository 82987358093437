<script setup>
import { computed } from "vue";
import { modules } from "@/composables/constans/modules";
import { useStore } from "@/store";
import HeaderProjectMenu from "@/components/GlobusHeader/HeaderMenu/HeaderProjectMenu.vue";
import HeaderUserProfile from "@/components/GlobusHeader/HeaderMenu/HeaderUserProfile.vue";
import HeaderLanguage from "@/components/GlobusHeader/HeaderMenu/HeaderLanguage.vue";
import HeaderServices from "@/components/GlobusHeader/HeaderMenu/HeaderServices.vue"; 

const store = useStore();
const useHeaderMenu = computed(() => {
  return modules.filter((module) => {
    return module.dataName === store.activeModule.dataName;
  })[0]?.meta?.useMenu;
});
</script>

<template>
  <div class="header-menu">
    <HeaderProjectMenu v-if="useHeaderMenu" />
    <HeaderUserProfile />
    <HeaderLanguage v-if="useHeaderMenu" />
    <HeaderServices v-if="useHeaderMenu" />
  </div>
</template>

<style scoped lang="scss">
.header-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>