<script setup>
import { t } from "@/controllerLK/GlobusLocalization";
import SvgIcon from "@/components/common/svgButton.vue";
import GlobusCheckboxSquare from "@/components/common/GlobusInput/GlobusCheckboxSquare.vue";

const emit = defineEmits(["closeProjectModal", "update:isArchive", "showDeleteProjectModal"]);

const props = defineProps({
  isArchive: {
    type: Boolean,
    default: false
  },
  isArchivePage: {
    type: Boolean,
    default: false
  }
});

</script>

<template>
  <div class="project-archive">
    <div
      v-if="!props.isArchivePage"
      class="project-archive__move"
    >
      <GlobusCheckboxSquare
        :check-box-value="props.isArchive"
        @on-change="value => emit('update:isArchive', value)"
      />
      {{ t('project_modal_archive.to_archive') }}
    </div>
    <div v-else />
    <div
      class="project-archive__delete"
      @click="emit('showDeleteProjectModal')"
    >
      <SvgIcon
        name="trash"
        width="15px"
        height="15px"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.project-archive {
  display: flex;
  justify-content: space-between;

  &__move {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 34px;
    height: 34px;
    background: $light-blue
  }
}
</style>