<script setup>
import {defineProps, defineEmits, ref, useTemplateRef} from "vue";
import SvgIcon from "@/components/common/svgButton.vue";

const props = defineProps({
  image: {
    type: String,
    default: null
  },
  isEdit: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:image"]); 

let image = ref("");
const imageInput = useTemplateRef("imageInput");

function addImg(){
  const reader = new FileReader();
  reader.readAsDataURL(imageInput.value.files[0]);
  reader.onload = function() {
    image.value = reader.result;
    emit("update:image", image.value);
  };
}

const isImageLoaded = ref(true);

</script>

<template>
  <div class="avatar-wrapper">
    <img
      v-if="props.image && isImageLoaded"
      ref="imageRef"
      class="user-avatar"
      :src="props.image"
      alt=""
      @error="isImageLoaded = false"
    >
    <SvgIcon
      v-else
      name="userPhotoUndefinedBig"
      class="user-avatar"
    />
    <div
      class="avatar__upload_hover"
      @click="imageInput.click()"
    >
      <input
        ref="imageInput"
        style="display: none;"
        type="file"
        :accept="'.jpg, .png'"
        @input="addImg"
      >

      <SvgIcon

        name="uploadPhotoHover"
        width="115px"
        height="115px"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.avatar-wrapper {
  position: relative;

  .avatar__upload_hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 285px;
    height: 320px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    cursor: pointer;

    &:hover {
      opacity: 1;
      background: #00000078;
      border-radius: 8px;
    }
  }

  .user-avatar {
    object-fit: cover;
    width: 285px;
    height: 320px;
    border-radius: 8px;
  }


}
</style>