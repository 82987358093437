<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3321 2.7489C10.3767 2.70437 10.437 2.67935 10.5 2.67935C10.5312
       2.67935 10.5621 2.68549 10.5909 2.69743C10.6197 2.70936 10.6459 2.72685
        10.6679 2.7489C10.69 2.77094 10.7075 2.79712 10.7194 2.82593C10.7313
         2.85473 10.7375 2.88561 10.7375 2.91679C10.7375 2.94797 10.7313 2.97884
          10.7194 3.00765C10.7075 3.03646 10.69 3.06263 10.6679 3.08468L3.5721
           10.1805L3.12439 10.2924L3.23632 9.8447L10.3321 2.7489ZM10.5 0.679352C9.90662
            0.679352 9.33751 0.915081 8.91791 1.33468L1.62625 8.62635C1.49809
             8.75451 1.40717 8.91509 1.36321 9.09092L0.779879 11.4243C0.694685
              11.765 0.794534 12.1255 1.04291 12.3739C1.29129 12.6223 1.65178
               12.7221 1.99256 12.6369L4.32589 12.0536C4.50172 12.0096 4.6623
                11.9187 4.79046 11.7906L12.0821 4.4989C12.2899 4.29113 12.4547
                 4.04448 12.5671 3.77302C12.6796 3.50156 12.7375 3.21061 12.7375
                  2.91679C12.7375 2.62296 12.6796 2.33202 12.5671 2.06056C12.4547
                   1.7891 12.2899 1.54245 12.0821 1.33468C11.8744 1.12692 11.6277
                    0.962108 11.3563 0.849667C11.0848 0.737225 10.7938 0.679352
                     10.5 0.679352ZM7.00002 10.6668C6.44774 10.6668
                      6.00002 11.1145 6.00002 11.6668C6.00002 12.2191 6.44774 12.6668
                       7.00002 12.6668H12.25C12.8023 12.6668 13.25 12.2191 13.25
                        11.6668C13.25 11.1145 12.8023 10.6668 12.25 10.6668H7.00002Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup lang="ts">
</script>