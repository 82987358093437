<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {computed} from "vue";
import {useUser} from "@/store/user";
import { t } from "@/controllerLK/GlobusLocalization";
import {useStore} from "@/store";

const props = defineProps({
  errorList: {
    type: Array,
    required: true
  },
  activeModuleList: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(["addModule", "deleteModule"]);

const store = useStore();
const userStore = useUser();
const availableModuleList = computed(() => userStore?.user?.modules.filter(item => item.moduleName !== "Главный"));

const toggleModule = (module) => {
  const isModuleActive = props.activeModuleList.indexOf(module);
  isModuleActive === -1 ? emit("addModule", module) : emit("deleteModule", isModuleActive);
};
</script>

<template>
  <div class="project-module-list-wrapper">
    <div class="project-module-list">
      <div
        v-for="module in availableModuleList.filter((module) => store.getEnabledModuleList().includes(module.moduleId))"
        :key="module"
        class="project-module-list__item"
        :class="{'project-module-list__item-active': props.activeModuleList.includes(module.moduleId)}"
        @click="toggleModule(module.moduleId)"
      >
        <SvgIcon
          :name="module?.moduleId"
          width="21px"
          height="21px"
        />
        {{ t('project_modal_modules.' + module.moduleId) }}
      </div>
    </div>
    <div
      v-if="props.errorList.find((i) => i === 'noModule')"
      class="text-error"
    >
      {{ t('project_modal_modules.error.required') }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.project-module-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  &__item {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border-radius: 22px;
    gap: 5px;
    padding: 4px 12px 4px 12px;
    border: 1px solid $grey-light;
    color: $blue;

    &:hover {
      background: $hover-active-color;
      border-color: $hover-active-color;
    }

    &-active {
      border-color: $globe-active-color;

      &:hover {
        border-color: $globe-active-color;
      }
    }


  }
}
</style>