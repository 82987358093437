<script setup>

import {t} from "@/controllerLK/GlobusLocalization";
import GlobusDialog from "@/components/common/GlobusDialog.vue";
import {useTemplateRef} from "vue";
import SvgIcon from "@/components/common/svgButton.vue";

const props = defineProps({
  isShowMapUploadDialog: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["close-map-upload-dialog", "add-region-to-list"]);

const fileInputRef = useTemplateRef("upload-input");

// Запрос на отправку загруженной карты
const uploadMapFile = (file) => {
  if (file.size > 10485760) {
    alert(t("project_modal_country.upload_dialog.validation"));
    return;
  }

  emit("close-map-upload-dialog");
  emit("add-region-to-list", {name: file.name, file: file});
};

</script>

<template>
  <GlobusDialog
    v-if="props.isShowMapUploadDialog"
    :title="t('project_modal_country.upload_dialog.title')"
    @cancel="emit('close-map-upload-dialog')"
  >
    <div
      class="input-form"
      @click="fileInputRef.click()"
    >
      <SvgIcon
        name="upload"
        width="16px"
        height="16px"
      />
      {{ t('project_modal_country.upload_dialog.message') }}
      <br>
      {{ t('project_modal_country.upload_dialog.validation') }}
      <input
        ref="upload-input"
        type="file"
        accept="application/geo+json"
        @input="(e) => uploadMapFile(e.target.files[0])"
      >
    </div>
  </GlobusDialog>
</template>

<style scoped lang="scss">

.input-form {
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  user-select: none;
  input {
    all: unset;
    display: none;
  }
}

</style>