<script setup>

import {onMounted, ref, watch} from "vue";
import { t } from "@/controllerLK/GlobusLocalization";
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import EditIcon from "@/assets/icons/EditIcon.vue";
import {useUser} from "@/store/user";
import TextInputSecondary from "@/pages/LKPage/LKWindow/LKProfileWindow/Input/TextInputSecondary.vue";
import LKUserAvatar from "@/pages/LKPage/LKWindow/LKProfileWindow/LKUserAvatar.vue";
import {useStore} from "@/store";

const store = useStore();
const userStore = useUser();

onMounted(async () => {
  userFormData.value = await userStore.getUser();
  setupWatcher();
});

const isProfileHasEdited =  ref(false);
const isFormSubmitted = ref(false);

let userFormData = ref({
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  image: "",
  email: ""
});

const errorList = ref({
  firstName: false,
  middleName: false,
  lastName: false,
  phone: false,
  email: false
});

const setupWatcher = () => {
  watch(userFormData, () => {
    isProfileHasEdited.value = true;
  }, {deep: true});
};

const saveUser = async () => {
  isFormSubmitted.value = true;
  errorList.value = {
    firstName: false,
    middleName: false,
    lastName: false,
    phone: false,
    email: false
  };

  if (userFormData.value.lastName !== null && userFormData.value.lastName.length <= 0) {
    errorList.value.lastName = t("lk_profile_window.error.required");
  } else {
    errorList.value.lastName = validateName(userFormData.value.lastName);
  }

  if (userFormData.value.middleName !== null && userFormData.value.middleName.length <= 0) {
    errorList.value.middleName = t("lk_profile_window.error.required");
  } else {
    errorList.value.middleName = validateName(userFormData.value.middleName);
  }

  if (userFormData.value.firstName !== null && userFormData.value.firstName.length <= 0) {
    errorList.value.firstName = t("lk_profile_window.error.required");
  } else {
    errorList.value.firstName = validateName(userFormData.value.firstName);
  }


  if (userFormData.value.email !== null && userFormData.value.email.length === 0) {
    errorList.value.email = t("lk_profile_window.error.required");
  } else if (!validateEmail(userFormData.value.email)) {
    errorList.value.email = t("lk_profile_window.error.email_format");
  }



  if (userFormData.value.phone !== null && userFormData.value.phone.length <= 0) {
    errorList.value.phone = t("lk_profile_window.error.required");
  }
  else if (!validatePhone(userFormData.value.phone)) {
    errorList.value.phone = t("lk_profile_window.error.phone_format");
  }

  for (const error in errorList.value) {
    if (errorList.value[error]) {
      isFormSubmitted.value = false;
      return false;
    }
  }

  userFormData.value.lastName = userFormData.value.lastName.trim();
  userFormData.value.middleName = userFormData.value.middleName.trim();
  userFormData.value.firstName = userFormData.value.firstName.trim();

  try {
    await userStore.updateUser(userFormData.value);
    isProfileHasEdited.value = false;
    isFormSubmitted.value = false;
    store.createNotify(t("lk_profile_window.toast.success"), "success");
  } catch (e) {
    store.createNotify("lk_profile_window.toast.error", "error");
  }

};

const validatePhone = (phone) => {
  const re = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm;
  return re.test(phone);
};

const validateEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  const startMail = email.split("@");
  const endMail = startMail[1].split(".");

  if (startMail[0].startsWith("-")) return false;
  if (startMail[0].endsWith("-")) return false;
  if (startMail[0].startsWith("_")) return false;
  if (startMail[0].endsWith("_")) return false;

  if (endMail[0].startsWith("_")) return false;
  if (endMail[0].endsWith("-")) return false;
  if (endMail[0].startsWith("_")) return false;
  if (endMail[0].endsWith("_")) return false;

  return re.test(email);
};

const validateName = (name) => {
  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)

  // Проверка на кириллицу убрана, task 1051
  // const re = /[\d\w(!@#$%^&*())(!"№;%:?*+=)]/giu;
  // if (re.test(name)) return t("lk_profile_window.error.cyrillic");

  if (emojiRegEx.test(name)) return t("lk_profile_window.error.format");
  if (name.startsWith("-")) return t("lk_profile_window.error.format");
  if (name.endsWith("-")) return t("lk_profile_window.error.format");



  // Заглавные буквы

  if (name[0] !== name[0].toUpperCase()) return t("lk_profile_window.error.format");

  for (let char in name) {
    if (char !== "0") {
      if (name[char] !== name[char].toLowerCase()) {
        return t("lk_profile_window.error.format");
      }
    }
  }

  return false;

};

</script>

<template>
  <div class="windows-wrapper">
    <LKUserAvatar
      :image="userFormData.image"
      @update:image="image => userFormData.image = image"
    />
    <div class="profile">
      <div class="profile-input">
        <div class="profile-input__label">
          {{ t('lk_profile_window.label.email') }}
        </div>
        <div class="profile-input__value">
          <TextInputSecondary
            :value="userFormData.email"
            place-holder="user@globus.ru"
            :error-message="errorList.email"
            @update:value="newValue => userFormData.email = newValue"
          />
        </div>
      </div>

      <div class="profile-input">
        <div class="profile-input__label">
          {{ t('lk_profile_window.label.phone') }}
        </div>
        <div class="profile-input__value">
          <TextInputSecondary
            :value="userFormData.phone"
            :error-message="errorList.phone"
            place-holder="+7 (800) 555-35-35"
            :mask="`+7 (###) ###-##-##`"
            type="tel"
            @update:value="newValue => userFormData.phone = newValue"
          />
        </div>
      </div>

      <div class="profile-fullname">
        <div class="profile-input profile-fullname__input">
          <div class="profile-input__label">
            {{ t('lk_profile_window.label.lastname') }}
          </div>
          <div class="profile-input__value">
            <TextInputSecondary
              :error-message="errorList.lastName"
              :value="userFormData.lastName"
              :place-holder="t('lk_profile_window.placeholder.lastname')"
              @update:value="newValue => userFormData.lastName = newValue"
            />
          </div>
        </div>

        <div class="profile-input profile-fullname__input">
          <div class="profile-input__label">
            {{ t('lk_profile_window.label.firstname') }}
          </div>
          <div class="profile-input__value">
            <TextInputSecondary
              :error-message="errorList.firstName"
              :value="userFormData.firstName"
              :place-holder="t('lk_profile_window.placeholder.firstname')"
              @update:value="newValue => userFormData.firstName = newValue"
            />
          </div>
        </div>


        <div class="profile-input profile-fullname__input">
          <div class="profile-input__label">
            {{ t('lk_profile_window.label.middlename') }}
          </div>
          <div class="profile-input__value">
            <TextInputSecondary
              :error-message="errorList.middleName"
              :value="userFormData.middleName"
              :place-holder="t('lk_profile_window.placeholder.middlename')"
              @update:value="newValue => userFormData.middleName = newValue"
            />
          </div>
        </div>
      </div>

      <div class="profile-control">
        <GlobusButtonPrimary
          :is-disabled="!isProfileHasEdited"
          :is-clicked="isFormSubmitted"
          class="profile-control__button"
          @click="saveUser"
        >
          <EditIcon
            style="color: #FFF; width: 14px; height: 14px;"
          />
          Сохранить изменения
        </GlobusButtonPrimary>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;

  &-control {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &__button {
      min-width: 200px;
    }
  }

  &-fullname {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &__input {
      flex-grow: 1;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    gap: 10px;



    &__label {
      color: $globus-background-secondary-text-color;
      font-weight: 400;
      font-size: 14px;
    }

    &__value {
      display: flex;
      width: 100%;
    }
  }
}

.windows-wrapper {
  display: flex;
  height: 100%;
  gap: 30px;
}

</style>