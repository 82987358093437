<script setup>

import {useProjects} from "@/store/projects";
import {computed, ref} from "vue";
import { t } from "@/controllerLK/GlobusLocalization";
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import BlockView from "@/components/common/BlockView.vue";
import {goLkPage, goMainPage, setProject} from "@/composables/ModuleCommunication";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import ArrowLeftIcon from "@/assets/icons/ArrowLeftIcon.vue";

const projectStore = useProjects();
const store = useStore();
const router = useRouter();

const isShowProjectDropdown = ref(false);

const projectList = computed(() => {
  return projectStore.projects.filter((project) => {
    return project.id !== projectStore.activeProject.id && project.modules.includes(store.activeModule.dataName);
  });
});

const setActiveProject = async (project) => {
  store.loader = true;
  isShowProjectDropdown.value = false;
  await projectStore.setActiveProject(project.id);
  setProject(project);
  store.loader = false;
};

</script>

<template>
  <div
    v-if="router.currentRoute.value.fullPath === '/lk'"
    class="project"
  >
    <div
      class="project__title"
      @click="goMainPage()"
    >
      <ArrowLeftIcon class="project__icon" />
      {{ t('header_project_menu.button.back') }}
    </div>
  </div>
  <div
    v-else
    class="project"
  >
    <div
      class="project__title"
      :class="{'project__title_active': isShowProjectDropdown}"
      @click="isShowProjectDropdown = !isShowProjectDropdown"
    >
      {{ t('header_project_menu.label.project') }}:
      {{ projectStore.activeProject.name ? projectStore.activeProject.name : '' }}
    </div>
    <div
      v-if="isShowProjectDropdown"
      class="project-dropdown"
    >
      <div class="project-dropdown__list scroll">
        <div
          v-if="projectStore.activeProject"
          class="project-dropdown__item_active"
        >
          <div class="item__title">
            {{ projectStore?.activeProject?.name }}
          </div>
          <div class="item__subtitle">
            {{ t('header_project_menu.label.current_project') }}
          </div>
        </div>
        <div
          v-for="project in projectList"
          :key="project.id"
          class="project-dropdown__item"
          @click="setActiveProject(project)"
        >
          {{ project.name }}
        </div>
      </div>
      <GlobusButtonPrimary @click="goLkPage(); store.showMode = 'projects'">
        {{ t('header_project_menu.button.open') }}
      </GlobusButtonPrimary>
    </div>
  </div>
  <BlockView
    v-if="isShowProjectDropdown"
    @click="isShowProjectDropdown = false"
  />
</template>

<style scoped lang="scss">
.project {
  display: flex;
  justify-content: flex-end;
  width: 200px;
  position: relative;


  &__icon {
    width: 16px;
    height: 16px;
    color: #373D3F;
  }

  &-dropdown {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    top: 50px;
    right: 0;
    background: #ffffff;
    padding: 6px;
    box-shadow: 1px 2px 10px 0 #131E2726;
    z-index: 2000;
    border-radius: 8px;
    width: calc(100% + 60px);


    &__list {
      max-height: 80vh;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      white-space: nowrap;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 6px 14px;
      height: 30px;
      transition: all .2s ease-in-out;

      &_active {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr;
        padding: 6px 14px;

        .item {
          display: flex;
          justify-content: space-between;

          &__title {
            white-space: nowrap;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__subtitle {
            color: #A7A7A7;
            text-align: end;
          }
        }
      }

      &:hover {
        border-radius: 8px;
        background: #EEF1F4;
      }
    }
  }

  &__title {
    color: #373D3F;
    display: inline-block;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    align-content: center;
    cursor: pointer;
    user-select: none;
    border-radius: 22px;
    background: #FFF;
    padding: 6px 14px;
    gap: 5px;
    height: 38px;
    transition: all .3s ease-in-out;

    &:hover {
      background: #1A64D433;

  }

    &_active {
      background: #1A64D433;
    }
  }
}
</style>