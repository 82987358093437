export const focus = {
  mounted(el) {
    el.focus();
  },
};

export const clickOutside = {
  mounted(el, binding) {
    const handleClickOutside = (event) => {
      if (typeof binding.value === "function") {
        if (!el.contains(event.target)) {
          binding.value();
        }
      } else {
        console.error("Binding value is not a function");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    el._clickOutsideHandler = handleClickOutside;
  },
  beforeUnmount(el) {
    document.removeEventListener("mousedown", el._clickOutsideHandler);
    delete el._clickOutsideHandler;
  },
};
