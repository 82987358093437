<script setup>
import {useUser} from "@/store/user";
import SvgIcon from "@/components/common/svgButton.vue";
import {getModuleUrl} from "@/composables/tools/services.tools";
import { t } from "@/controllerLK/GlobusLocalization";
import {useStore} from "@/store";

const store = useStore();
const userStore = useUser(); 

const goToModule = (module) => {
  window.open(getModuleUrl(module));
};

</script>

<template>
  <div class="windows-wrapper">
    <div class="header">
      <div class="header__title">
        {{ t('lk_service_window.title') }}
      </div>
    </div>

    <div class="body">
      <div class="services">
        <div
          v-for="module in userStore.user?.modules?.
            filter((userModule) => store.getEnabledModuleList().includes(userModule.moduleId) )"
          :key="module"
          class="services__item"
          @click="goToModule(module)"
        >
          <SvgIcon
            width="62px"
            height="62px"
            :name="module.moduleId"
          />
          {{ t('lk_service_window.' + module.moduleId) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

@import '@/assets/styles/vars.scss';

.windows-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 33px;

  .header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 28px;
      font-weight: 400;
    }

    &__search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .body {
    .services {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 22px;

      &__item {
        cursor: pointer;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;
        height: 165px;
        padding: 20px;
        max-width: 450px;
        justify-content: space-between;
        border-radius: 8px;
        background: $light-blue;

        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}


</style>