<script setup>
import { t } from "@/controllerLK/GlobusLocalization";

const props = defineProps({
  projectName: {
    type: String,
    required: true
  },
  projectDescription: {
    type: String,
    required: true
  },
  errorList: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(["update:projectName", "update:projectDescription"]);

</script>

<template>
  <div class="project-name">
    <input
      :value="props.projectName"
      :class="{'input-error': errorList.filter((i) => i === 'noName').length > 0}"
      class="project-name__input"
      :placeholder="t('project_modal_name.placeholder.name')"
      type="text"
      @input="emit('update:projectName', $event.target.value)"
    >
    <div
      v-if="errorList.find((i) => i === 'noName')"
      class="text-error"
    >
      {{ t('project_modal_name.error.required') }}
    </div>
    <div
      v-if="errorList.find((i) => i === 'noEmoji')"
      class="text-error"
    >
      {{ t('project_modal_name.error.emoji') }}
    </div>
  </div>

  <div class="project-description">
    <textarea
      :value="props.projectDescription"
      class="project-description__input"
      :placeholder="t('project_modal_name.placeholder.description')"
      @input="emit('update:projectDescription', $event.target.value)"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.project-name {
  .project-name__input {
    width: 100%;
    height: 40px;
    padding: 8px 12px 8px 12px;
    border: 1px solid $grey-light;
    border-radius: 8px;
  }
}

.project-description {
  .project-description__input {
    width: 100%;
    height: 190px;
    padding: 8px 12px 8px 12px;
    resize: none;
    border: none;
    border-radius: 8px;
    outline: none;
    background: $light-blue;
  }
}


</style>