<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {ref} from "vue";

const props = defineProps({
  image: {
    type: String,
    required: true
  }
});

const isImageLoaded = ref(true);
</script>

<template>
  <img
    v-if="props.image && isImageLoaded"
    class="user-avatar"
    :src="props.image"
    alt="avatar"
    @error="isImageLoaded = false"
  >
  <SvgIcon
    v-else
    width="20px"
    height="20px"
    name="undefined-photo"
  />
</template>

<style scoped lang="scss">

.user-avatar {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 50%;
}

</style>