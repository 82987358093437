import {defineStore} from "pinia";
import axios from "axios";
import { setProject } from "@/composables/ModuleCommunication";
import {useStore} from "@/store/index";
import {useUser} from "@/store/user";
import { t } from "@/controllerLK/GlobusLocalization";

export const useProjects = defineStore({
    id: "projectsStore",
    state: () => ({
        archivedProjects: [],
        projects: [],
        activeProject: {mapFiles: [], modules: []},
        countries: [],
        foundedCountryId: "",
        foundedRegionList: []
    }),
    actions: {
        async getProject(id){
            const mainStore = useStore();

            try {
                const result = await axios.get(`/globus/api/Projects/${id}`);
                return result.data;
            } catch (e) {
                mainStore.createNotify(t("notification.get_project.error"), "error");
            }
        },

        async grantAccessToProject(projectId, userList) {
            try {
                await axios.put(`/globus/api/User/project/access/${projectId}`, userList);
            } catch (e) {
                console.log(e);
            }
        },

        async removeAccessFromProject(projectId, userList) {
          try {
              await axios.delete(`/globus/api/User/project/deaccess/${projectId}`, {data: userList});
          }  catch (e) {
              console.log(e);
          }
        },

        

        async getProjects(isArchived = false){
            const mainStore = useStore();

            try {
                const response = await axios.get(`/globus/api/Projects?archival=${isArchived}`);
                isArchived ? this.archivedProjects = response.data : this.projects = response.data;
            } catch (e) {
                mainStore.createNotify(t("notification.get_project.error"), "error");
            }

        },

        async createProject(data){
            const mainStore = useStore();

            try {
                const result = await axios.post("/globus/api/Projects", data);
                this.projects.push(result.data);
                mainStore.createNotify(t("notification.project_created.message"), "success");
                return result.data;
            } catch (e) {
                mainStore.createNotify(t("notification.project_created.error"), "error");
            }
        },
        async updateProject(data){
            const mainStore = useStore();
            const userStore = useUser();
            try {
                await axios.patch(`/globus/api/Projects/${data.id}`, data);
                this.activeProject = await this.getProject(userStore.user.activeProject);
                mainStore.createNotify(t("notification.save.message"), "success");
            } catch (e) {
                mainStore.createNotify(t("notification.save.error"), "error");
            }

        },
        async changeProjectArchive(data){
            const mainStore = useStore();
            const userStore = useUser();

            try {
                await axios.patch(`/globus/api/Projects/${data.id}`, data);
                mainStore.createNotify(t("notification.save.message"), "success");
                this.activeProject = await this.getProject(userStore.user.activeProject);
                await this.getProjects(!data.isArchive);
            } catch (e) {
                mainStore.createNotify(t("notification.save.error"), "error");
            }

        },
        async deleteProject(id, isArchived){
            const mainStore = useStore();

            if (id === this.activeProject.id) {
                mainStore.createNotify(t("notification.delete_active_project.error"), "error");
                return false;
            }

            try {
                await axios.delete(`/globus/api/Projects/${id}`);
                await this.getProjects(isArchived);
                mainStore.createNotify(t("notification.project_delete.message"), "success");
            } catch (e) {
                mainStore.createNotify(t("notification.project_delete.error"), "error");
            }
        },

        async setActiveProject(id) {
            const mainStore = useStore();
            const userStore = useUser();

            try {
                const result = await axios.patch("/globus/api/User/profile", {activeProject: id});
                userStore.activeProject = result.data;
                this.activeProject = this.projects.find(project => project.id === id);
            } catch (e) {
                mainStore.createNotify(t("notification.set_active_project.error"), "error");
            }
        },
        async searchRegion(value) {

            try {
                const result = await axios.get(`/globus/api/MapFile/locations/${value}`);
                this.foundedRegionList = result.data;
            } catch (e) {
                this.foundedRegionList = [];
                console.log(e);
            }

            return this.foundedRegionList;
        },

        async createMap(projectId, locationId, mapName) {
            const result = await axios.post(`/globus/api/MapFile/projects/${projectId}/maps/${locationId}`,
                {mapName: mapName});

            const projectInStore = this.projects.filter((project) => {
                return project.id === projectId;
            });

            if (projectId === this.activeProject.id) {
                setProject(projectInStore[0]);
                this.activeProject = projectInStore[0];
            }

            projectInStore[0].mapFiles.push(result.data);
        },

        async uploadMap(projectId, map) {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            let fd = new FormData();
            fd.append("formFile", map);
            fd.append("MapName", map.name);

            const response = await axios.post(`/globus/api/MapFile/projects/${projectId}/maps/upload`, fd, config);

            const projectInStore = this.projects.filter((project) => project.id === projectId);
            projectInStore[0].mapFiles.push(response.data);

            if (projectId === this.activeProject.id) {
                setProject(projectInStore[0]);
                this.activeProject = projectInStore[0];
            }

        },

        async deleteProjectMap(projectId, map) {
            await axios.delete(`/globus/api/MapFile/projects/${projectId}/maps/${map.name}`);

            const projectInStore = this.projects.filter((project) => project.id === projectId);
            projectInStore[0].mapFiles.splice(projectInStore[0].mapFiles.indexOf(map), 1);

            if (projectId === this.activeProject.id) {
                setProject(projectInStore[0]);
                this.activeProject = projectInStore[0];
            }
        },

        clearData() {
            this.projects = [];
            this.archivedProjects = [];
            this.activeProject = {mapFiles: [], modules: []};
            this.countries = [];
            this.foundedCountryId = "";
            this.foundedRegionList = [];

            setProject(""); // очистка юзера в приложение
        }
    }
});
